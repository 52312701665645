import React from 'react';
import QueueAnim from 'rc-queue-anim';
import { Row, Col, Image } from 'antd';
import {OverPack} from 'rc-scroll-anim';
import Rivolie from './img/Rivolie.jpeg'
import logosv from './img/Logo Sport Vlaanderen.jpg'
import agradi from './img/Agradi_partnerprogramma_logo_kleur.png'
class Content extends React.PureComponent {
  render() {
    return (
      <div className='home-page-wrapper content0-wrapper' >
        <div className= 'home-page content0'>

          <OverPack  playScale={ 0.3} key='test'>
          <div className="page2-title">
            Sponsors
          </div> 
          <div>
            Volgende partners ondersteunen onze ponywerking en zorgen ervoor dat we kwalitatieve lessen kunne aan bieden aan onze leden.
          </div>
          <Row style={{margin:"10px"}}>
            <Col>
              <a href="https://agradi.nl/" target="_blank"><img src={agradi} alt="agradi" style={{ width:"80%", marginRight:"7px"}}/></a> <br/>
              Agradi is dé online winkel voor paarden gerief. Ponylessen 't Perhof is onderdeel van het Agradi Partner programma.  <br/>
              Vraag gerust onze partnercode voor 5% korting. Bestel online op <a href="https://agradi.nl/" target="_blank">Agradi.nl</a>.
            </Col>
            
          </Row>
          </OverPack>
      </div>
    </div>
    );
  }
}

export default Content;
